import { List } from '@mui/material'
import { useCallback, useContext, useEffect, useState } from 'react'
import { OrgContext } from '../../../../utils/context'
import { get } from '../../../../utils/api'
import {
  CentreListItem,
  CentreListSkeleton
} from './components/centre_list_item'
import CentresModal from './components/create_centre_modal'
import { isOwner } from '../../../../utils/auth'
import { CreateCentreAssignmentGroup } from './components/create_centre_assignment_group'
import { WarningDialogModal } from '../../../reusable/Modal'
const CentresTab = ({ setAlert, update }) => {
  const org = useContext(OrgContext)
  const org_id = org ? org.organisation_id : null
  const [isLoading, setIsLoading] = useState(true)
  const [centresModal, setCentresModal] = useState(false)
  const [centres, setCentres] = useState([])
  const [selectedCentre, setSelectedCentre] = useState(null)
  const [allOrgEducators, setAllOrgEducators] = useState([])
  const [assigmentGroupModal, setAssigmentGroupModal] = useState(false)
  const [warningModal, setWarningModal] = useState(false)
  const [selectedCentreId, setSelectedCentreId] = useState(null)
  const [reload, setReload] = useState(true)
  const [selectedAssignmentGroupId, setSelectedAssignmentGroupId] =
    useState(null)
  const warningMessage =
    'You have open shifts assigned to this centre group. Please change who the shift is assigned to.'
  const fetchOrgEducators = useCallback(async () => {
    const res = await get(`/manage/organisations/${org_id}/educators`)
    setAllOrgEducators(res)
  }, [org_id])

  const fetchCentres = useCallback(() => {
    if (!reload && !update) return
    if (assigmentGroupModal || centresModal) return
    setIsLoading(true)
    get(`/manage/organisations/${org_id ?? 0}/centres`).then((res) => {
      setCentres(res)
      setIsLoading(false)
      setReload(false)
    })
  }, [reload, update, assigmentGroupModal, centresModal, org_id])

  useEffect(() => {
    fetchCentres()
  }, [fetchCentres, update])

  useEffect(() => {
    fetchOrgEducators()
  }, [fetchOrgEducators])

  function closeModal(reload) {
    setCentresModal(false)
    setSelectedCentre(null)
    setReload(reload)
  }
  function openCentreModal(centre) {
    setSelectedCentre(centre)
    setCentresModal(true)
  }
  function openAssignmentGroup(centre) {
    setSelectedCentreId(centre.id)
    setAssigmentGroupModal(true)
    setSelectedAssignmentGroupId(centre.assignment_group.id)
  }
  function onCloseAssignmentGroup(reload, message) {
    if (message) {
      if (message.startsWith('You have open shifts')) {
        setWarningModal(true)
      } else {
        setAlert(message, 'success')
      }
    }
    setAssigmentGroupModal(false)
    setReload(reload)
  }

  const handleCentreUpdate = (updatedCentre) => {
    setCentres(prevCentres => {
      const index = prevCentres.findIndex(c => c.id === updatedCentre.id);
      if (index !== -1) {
        // Update existing centre
        const updatedCentres = [...prevCentres];
        if (index !== -1) {
          updatedCentres[index] = { ...updatedCentres[index], ...updatedCentre };
        }
        return updatedCentres;
      }
      // Add new centre
      return [...prevCentres, updatedCentre];
    });
  };

  return (
    <div>
      {isLoading ? (
        <CentreListSkeleton></CentreListSkeleton>
      ) : (
        <List>
          {centres.map((centre) => (
            <CentreListItem
              key={centre.id}
              centre={centre}
              editable={isOwner(org)}
              openAssignmentGroup={() => openAssignmentGroup(centre)}
              onEditClick={() => openCentreModal(centre)}
            />
          ))}
        </List>
      )}
      <CreateCentreAssignmentGroup
        open={assigmentGroupModal}
        editable={true}
        org_id={org_id}
        centre_id={selectedCentreId}
        allOrgEducators={allOrgEducators}
        assignmentGroupId={selectedAssignmentGroupId}
        onClose={(reload, message) => onCloseAssignmentGroup(reload, message)}
      />
      <WarningDialogModal
        open={warningModal}
        onClose={() => setWarningModal(false)}
        title="Warning"
        message={warningMessage}
      />
      <CentresModal
        open={centresModal}
        orgId={org_id}
        centre={selectedCentre}
        onClose={closeModal}
        editable={isOwner(org)}
        onCentreUpdate={handleCentreUpdate}
      />
    </div>
  )
}
export default CentresTab
